import React from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';

interface IGdnDropzoneProps {
    getFiles: <T extends File>(files: T[]) => void;
    dropzoneLabel: string;
    fileButtonText: string;
    infoSection: React.ReactNode;
    accept: Accept;
    handlereject: (fileRejections: FileRejection[]) => void;
}

export const GdnDropzone: React.FC<IGdnDropzoneProps> = ({
    getFiles,
    dropzoneLabel,
    fileButtonText,
    infoSection,
    accept,
    handlereject
}) => {
    const { getRootProps, open, isDragAccept, isDragReject } = useDropzone({
        onDrop: getFiles,
        accept,
        onDropRejected: handlereject,
        useFsAccessApi: true
    });

    return (
        <section>
            <div
                {...getRootProps()}
                className={`dropzone ${isDragAccept ? 'dropzone__accept' : ''} ${isDragReject ? 'dropzone__reject' : ''}`}
            >
                <p>{dropzoneLabel}</p>
            </div>
            <div className='dropzone-footer'>
                <div className='dropzone-footer__info'>{infoSection}</div>
                <div className='dropzone-footer__button'>
                    <button type={'button'} onClick={open}>
                        {fileButtonText}
                    </button>
                </div>
            </div>
        </section>
    );
};
